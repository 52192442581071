@media (max-width: 761px) { 
    h1 {
        font-size: 160%;
        font-weight:500;
    }
    .right {
        margin: auto;
        max-width: 30%;
        text-align: right;             
        word-wrap: break-word;
        direction: rtl;
        margin-right:26px;            
        line-height: 100%;
        margin-top:10px;
        overflow-wrap: break-word;
    }
    .left {
        margin: auto;
        max-width: 30%;
        text-align: left;
        word-wrap: break-word;
        direction: ltr;
        margin-left: 26px;
        line-height: 100%;
        margin-top:10px; 
        overflow-wrap: break-word;
    }
}
@media (min-width: 767px) { 
    h1 {
        font-size: 900%;
        font-weight:500;
      }
      .right {
          margin: auto;
          max-width: 30%;
          text-align: right;             
          word-wrap: break-word;
          direction: rtl;
          margin-right:16px;            
          line-height: 100%;
          margin-top:10px;
          overflow-wrap: break-word;
      
      }
      .left {
          margin: auto;
          max-width: 30%;
          text-align: left;
          word-wrap: break-word;
          direction: ltr;
          margin-left: 16px;
          line-height: 100%;
          margin-top:10px; 
          overflow-wrap: break-word;
      }
}
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{


}

#root {
  position: absolute;
  height: 100%;
  width: 100%;
}
